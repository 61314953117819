import clsx from 'clsx';

import { useBoolean } from 'hooks';
import { ReactComponent as ArrowNavIcon } from 'assets/images/arrowNav.svg';

import s from './s.module.scss';

const Accordion = ({ label, content, mb = 10, isSpaceBetween, isLineBottom }) => {
  const [isOpen, setIsOpen] = useBoolean(false);

  return (
    <div className={clsx(isLineBottom && s.bottom_line)} style={{ marginBottom: `${mb}px` }}>
      <button
        type="button"
        className={clsx(s.acc_btn, isSpaceBetween && s.space_between, isOpen && s.acc_btn_open)}
        onClick={setIsOpen.toggle}
      >
        {label} <ArrowNavIcon />
      </button>
      <div className={clsx('acc', isOpen && 'acc_open')}>
        <button type="button" onClick={setIsOpen.off} className={s.content}>
          {content}
        </button>
      </div>
    </div>
  );
};

export default Accordion;
