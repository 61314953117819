import { IntroPage, OtherPresets } from 'components';
import IMAGE from 'assets/images';
import Articles from './Articles';

const Blog = () => {
  return (
    <div className="smooth_enter_an mb60">
      <IntroPage image={IMAGE.christinHume} title="Blog" />
      <Articles />
      <OtherPresets />
    </div>
  );
};

export default Blog;
