import IMAGE from 'assets/images/feedback';

export const REVIEW_1 = [
  {
    id: '146ad730-241e-4f5c-908f-d7de28eb5cb1',
    image: IMAGE.feedback1,
    name: 'Danielle S.',
    feedback:
      'So far so good, I`ve had the presets and brushes just a couple days but dang! They make my workflow a lot smoother and give my images the pop I was looking for!',
    rating: 4.5,
  },
  {
    id: '035c3a28-ff9a-459d-8f53-27f8fc541c9a',
    image: IMAGE.feedback2,
    name: 'Samantha M.',
    feedback:
      'I love these affects soooo much they have taken my editing to a whole new level everything has such awesome affects in this collection! It`s fun yet not too much! Every client I have used these with has been beyond impressed!!',
    rating: 5,
  },
  {
    id: '7686861b-04cc-4875-aaf1-aa3a46c44591',
    image: IMAGE.feedback3,
    name: 'Kristina L.',
    feedback:
      'Again I don`t know what took me so long to buy these?! They are wonderful to add that last perfect touch to your photos. Absolutely love them!',
    rating: 5,
  },
  {
    id: 'e64b8ec1-60da-4dc3-9318-f973f73bbd69',
    image: IMAGE.feedback4,
    name: 'Kayla F.',
    feedback:
      'If you`re looking to bring your photos to the next level, I recommend this preset! It`s absolutely stunning and easy to work with. The vertical beta versions don`t work for me, but there are plenty of other options',
    rating: 4,
  },
  {
    id: '18ca6c72-c7af-43d2-b344-3bd932c8510f',
    image: IMAGE.feedback5,
    name: 'Katherine M.',
    feedback:
      'Losing count how many PP I have and which is my favorite. This adds so much to the ordinary picture you didn`t think would make the cut... or just that added pop to an already awesome shot giving it Wow factor!',
    rating: 5,
  },
  {
    id: '1e45b037-a443-4219-98c1-d8223d572c7f',
    image: IMAGE.feedback6,
    name: 'Mike G.',
    feedback:
      'I really love these presets. They look very natural in the images and they are very easy to modify by moving them around the pictures or lowering the intensity, etc. I`m really happy that I bought them and they really take my images to a new level.',
    rating: 5,
  },
];
