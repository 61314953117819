import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useBoolean } from 'hooks';
import { Logo } from 'components';
import { MobileMenu } from 'components/_Modals';
import { ROUTER_PATHS } from 'constants/enums';
import { ReactComponent as CartIcon } from 'assets/images/cart.svg';
import MenuItem from './MenuItem';
import { INSTALL_MENU } from './const';

import s from './s.module.scss';

const Header = () => {
  const { cart } = useSelector(({ cartSlice }) => ({
    cart: cartSlice.cart,
  }));
  const [isOpenMenu, setIsOpenMenu] = useBoolean(false);

  const isFilledCart = !!cart?.length || 0;

  return (
    <>
      <header className={s.header}>
        <div className="main_container">
          <div className={s.header_wrapper}>
            <Logo theme="light" />
            <div className={s.nav}>
              <Link to={ROUTER_PATHS.HOME} className={s.nav_item}>
                Home
              </Link>
              <Link to={ROUTER_PATHS.SHOP} className={s.nav_item}>
                Shop
              </Link>
              <Link to={ROUTER_PATHS.BLOG} className={s.nav_item}>
                Blog
              </Link>
              <MenuItem label="Install" menu={INSTALL_MENU} />
            </div>
            <div className={s.cart_wrapper}>
              <Link to={ROUTER_PATHS.CART} className={s.cart_btn}>
                <CartIcon />
                {isFilledCart ? <span className={s.mark} /> : null}
              </Link>
            </div>
            <button
              type="button"
              className={clsx('burger_btn', s.burger_btn, isOpenMenu && 'burger_btn_open')}
              onClick={setIsOpenMenu.on}
            >
              <span />
            </button>
          </div>
        </div>
      </header>
      <MobileMenu isOpen={isOpenMenu} onClose={setIsOpenMenu.off} />
    </>
  );
};

export default Header;
