import { Link } from 'react-router-dom';

import { ROUTER_PATHS } from 'constants/enums';

import s from './s.module.scss';

const ArticleCard = ({ content }) => {
  return (
    <Link to={`${ROUTER_PATHS.ARTICLE}${content?.id}`} className={s.card}>
      <div className={s.img_wrapper}>
        <img src={content?.image} alt="article" />
      </div>
      <p title={content?.name} className={s.name}>
        {content?.name}
      </p>
      <p title={content?.desc} className={s.text}>
        {content?.desc}
      </p>
      <button type="button" className={s.read_more}>
        Read More
      </button>
    </Link>
  );
};

export default ArticleCard;
