import { Accordion } from 'components';

import { FAQ } from './const';

import s from './s.module.scss';

const Faq = () => {
  return (
    <div className="main_container mb60">
      <h2 className="title">FAQ</h2>
      <div className={s.faq_wrapper}>
        {FAQ.map(({ label, text }) => (
          <Accordion
            key={label}
            isSpaceBetween
            isLineBottom
            label={label}
            content={<p className={s.faq_content}>{text}</p>}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
