import { useEffect } from 'react';

import { useBoolean } from 'hooks';
import { VALIDATION } from 'constants/enums';
import IP_API from 'api/ip';

const ProtectByIP = ({ children }) => {
  const [isLoaded, setIsLoaded] = useBoolean(false);

  const handleProtectByCountry = (country) => {
    IP_API.getIP()
      .then(({ data }) => {
        if (country.includes(data.country_name)) {
          window.location.href = 'https://www.google.com./';
        } else {
          setIsLoaded.on();
        }
      })
      .catch(() => {
        window.location.href = 'https://www.google.com./';
      });
  };

  useEffect(() => {
    handleProtectByCountry(VALIDATION.BLOCKED_COUNTRIES);
  }, []);

  if (!isLoaded) {
    return null;
  }
  return <div>{children}</div>;
};

export default ProtectByIP;
