import { CONTACTS } from 'constants/enums';

export const FAQ = [
  {
    label: 'How do I download after I`ve purchased?',
    text: `After you've purchased from ${CONTACTS.APP_NAME}, you will immediately receive an email with a link to download your purchased item(s).`,
  },
  {
    label: 'Can I share my purchase with anyone?',
    text: 'Our presets are protected by copyright and other intellectual property laws. Sharing any content constitutes a breach of copyright and is strictly forbidden.',
  },
  {
    label: 'How do I unzip the file?',
    text: 'Our actions, overlays, and presets involve multiple data files and need to be compressed into a single .zip file for organizational purposes. Most computers already have built-in .zip extracting software, making it easy to open .zip files. If your computer does not have built-in .zip extracting software, you can download unzipping programs online for your PC or Mac.',
  },
  {
    label: 'How do I use the presets?',
    text: 'Once you`ve completed your purchase, you`ll receive an email to download the presets along with our step by step video tutorial to help walk you through the download process so you can get started editing your photos quickly and easily!',
  },
  {
    label: 'Do I need to be a professional photographer to use presets?',
    text: 'Not at all. Our presets are designed for anyone and everyone to use. Transform your everyday photos in just a click! No experience needed. We`ve designed our presets so that anyone can bring life to their every day moments!',
  },
  {
    label: 'Can I use it on a different phone or PC other than the one I installed it to?',
    text: 'Yes. Your purchased presets are downloadable with no expiration date. Just note that we have different presets for mobile and desktop.',
  },
  {
    label: 'Do you accept refunds?',
    text: 'Due to the nature of digital products, we cannot issue a refund once our files have been downloaded. However, we will consider granting a refund that meets certain internal criteria (e.g. we will be happy to issue a refund for duplicate orders), and will grant refunds at our reasonable discretion. Nothing herein grants an obligation by Wilde Presets to issue a refund. We`re incredibly responsive and take great care of our customers! Please feel free to contact us with any questions or concerns and we`ll be happy to help.',
  },
];
