import { useEffect } from 'react';

const useDynamicPageTitleAndFavicon = (content) => {
  const { title = 'SimplyPreset', faviconName = 'favicon' } = content || {};

  useEffect(() => {
    const changeTitleAndFavicon = () => {
      document.title = title;
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = `/${faviconName}.ico`;
      } else {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = `/${faviconName}.ico`;
        document.head.appendChild(newFavicon);
      }
    };

    changeTitleAndFavicon();
  }, [title, faviconName]);
};

export default useDynamicPageTitleAndFavicon;
