import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';

import { Logo } from 'components';
import { ROUTER_PATHS } from 'constants/enums';

import 'assets/style/helper.scss';
import s from './s.module.scss';

const MobileMenu = ({ isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        modal: s.modal,
        overlay: s.modal_overlay,
        closeButton: s.modal_closeBtn,
        modalAnimationIn: 'fade-in',
        modalAnimationOut: 'fade-out',
      }}
      focusTrapped={false}
    >
      <div className={s.menu_wrapper}>
        <Logo onClick={onClose} />
        <Link to={ROUTER_PATHS.SHOP} className={s.nav_item} onClick={onClose}>
          Shop
        </Link>
        <Link to={ROUTER_PATHS.HOME} className={s.nav_item} onClick={onClose}>
          Blog
        </Link>
        <Link to={ROUTER_PATHS.HOME} className={s.nav_item} onClick={onClose}>
          Install
        </Link>
        <Link to={ROUTER_PATHS.CART} className={s.nav_item} onClick={onClose}>
          Cart
        </Link>
      </div>
    </Modal>
  );
};

export default MobileMenu;
