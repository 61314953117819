import { Rating } from 'react-simple-star-rating';

import { trimFirstLetter } from 'utils/stringFormat';

import s from './s.module.scss';

const ReviewCard = ({ desc }) => {
  const userInitials = desc?.name
    ?.split(' ')
    ?.reduce((acc, name, i) => `${acc + trimFirstLetter(name) + (!i ? '.' : '')}`, '');

  return (
    <div className={s.card}>
      <div className={s.img_wrapper}>
        <img src={desc?.image} alt="review" />
      </div>
      <div className={s.desc}>
        <div className={s.initials}>{userInitials || 'A.A'}</div>
        <p className={s.name}>{desc?.name}</p>
        <p className={s.feedback}>{desc?.feedback}</p>
        <Rating initialValue={desc?.rating} size={25} fillColor="#4B749F" readonly />
      </div>
    </div>
  );
};

export default ReviewCard;
