import { ToastContainer } from 'react-toastify';

import Router from 'router';
import { Loader, ToTop } from 'components';

import 'assets/style/reset.scss';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/style/global.scss';

const App = () => {
  return (
    <>
      <Router />
      <ToastContainer position="bottom-right" />
      <Loader />
      <ToTop />
    </>
  );
};

export default App;
