import { ParallaxBanner } from 'react-scroll-parallax';

import s from './s.module.scss';

const IntroPage = ({ image, title }) => {
  return (
    <div className={s.intro}>
      <ParallaxBanner layers={[{ image, speed: -20 }]} className={s.parallax}>
        <div className={s.parallax_content}>
          <h2 className="intro_title">{title}</h2>
        </div>
      </ParallaxBanner>
      <p className={s.hint} />
    </div>
  );
};

export default IntroPage;
