import bgHill from './bg_hill.webp';
import about1 from './about1.webp';
import about2 from './about2.webp';
import seller1 from './seller1.webp';
import girlEyes from './girl_eyes.webp';
import photoPettine from './photo_pettine.webp';
import christinHume from './christin_hume.webp';
import towfiqu from './towfiqu.webp';
import kellySikkema from './kelly_sikkema.webp';
import cytonn from './cytonn.webp';
import cart from './cart.webp';

const Images = {
  bgHill,
  about1,
  about2,
  seller1,
  girlEyes,
  photoPettine,
  christinHume,
  towfiqu,
  kellySikkema,
  cytonn,
  cart,
};

export default Images;
