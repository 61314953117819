import {
  Home,
  Shop,
  Preset,
  Cart,
  Blog,
  Article,
  RefundPolicy,
  PrivacyPolicy,
  TermsOfService,
} from 'pages';
import { FastSpringPayment } from 'pages/_Payments';
import { BasicLayout } from 'pages/_Layout';
import { ProtectByIP } from 'components';
import { ROUTER_PATHS } from 'constants/enums';

export const PUBLIC_ROUTES = [
  {
    path: ROUTER_PATHS.HOME,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <Home />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: ROUTER_PATHS.SHOP,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <Shop />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: `${ROUTER_PATHS.PRESET}:id`,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <Preset />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: ROUTER_PATHS.CART,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <Cart />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: ROUTER_PATHS.BLOG,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <Blog />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: `${ROUTER_PATHS.ARTICLE}:id`,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <Article />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: ROUTER_PATHS.REFUND_POLICY,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <RefundPolicy />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: ROUTER_PATHS.TERMS_OF_SERVICE,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <TermsOfService />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  {
    path: ROUTER_PATHS.PRIVACY_POLICY,
    element: (
      <ProtectByIP>
        <BasicLayout>
          <PrivacyPolicy />
        </BasicLayout>
      </ProtectByIP>
    ),
  },
  // {
  //   path: ROUTER_PATHS.TWO_CHECKOUT_PAYMENT,
  //   element: <TwoCheckoutPayment />,
  // },
  {
    path: ROUTER_PATHS.FAST_SPRING_PAYMENT,
    element: <FastSpringPayment />,
  },
];
