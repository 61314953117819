import { useState, useCallback } from 'react';

const useFieldsState = (values) => {
  const [form, setForm] = useState(values || {});

  const onChange = useCallback((newValues) => {
    setForm((currState) => ({ ...currState, ...newValues }));
  }, []);

  return [form, onChange];
};

export default useFieldsState;
