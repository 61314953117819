import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IntroPage, OtherPresets } from 'components';
import { ROUTER_PATHS } from 'constants/enums';
import BLOG from 'constants/blog';

const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [article, setArticle] = useState({});

  const handleSetArticle = () => {
    const foundArticle = BLOG.find((item) => item.id === id);
    if (!id || !foundArticle) {
      toast.warning('Article not found');
      navigate(ROUTER_PATHS.BLOG, { replace: true });
      return;
    }
    setArticle(foundArticle);
  };

  useEffect(() => {
    handleSetArticle();
  }, [id]);

  return (
    <div className="smooth_enter_an">
      <IntroPage image={article.image} title={article.name} />
      <div className="main_container mb60">
        <div className="page_content">{article.content}</div>
      </div>
      <OtherPresets />
    </div>
  );
};

export default Article;
