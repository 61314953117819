import { useParams } from 'react-router-dom';

import { SellCard } from 'components/_Cards';
import { BasicSlider } from 'components/_Sliders';
import { OtherPresetSetting } from 'components/_Sliders/settings';
import { randomInteger } from 'utils/randomize';
import PRESETS from 'constants/presets';

const OtherPresets = () => {
  const { id } = useParams();

  const getRandomPresets = (length = 5) => {
    const uniqueIndexes = new Set();

    while (uniqueIndexes.size !== length) {
      const randomIndex = randomInteger(0, PRESETS.length - 1);

      if (PRESETS[randomIndex].id !== id) {
        uniqueIndexes.add(randomIndex);
      }
    }

    return [...uniqueIndexes].map((index) => PRESETS[index]);
  };

  return (
    <div className="main_container mb40 mt100">
      <h3 className="title">YOU MAY ALSO LIKE </h3>
      <BasicSlider settings={OtherPresetSetting()}>
        {getRandomPresets().map((preset) => (
          <SellCard key={preset.id} desc={preset} />
        ))}
      </BasicSlider>
    </div>
  );
};

export default OtherPresets;
