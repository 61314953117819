import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useForm } from 'hooks';
import { Input, Button, Rating } from 'components';
import { setLoader } from 'store/loaderSlice';
import { randomInteger } from 'utils/randomize';
import { FEEDBACK_SCHEME } from 'constants/validateForm';

import s from './s.module.scss';

const FeedbackForm = ({ onCloseModal }) => {
  const dispatch = useDispatch();

  const { form, setValue, errors, handleFormSubmit } = useForm(
    {
      firstName: '',
      lastName: '',
      feedback: '',
      rating: 5,
    },
    FEEDBACK_SCHEME
  );

  const handleSubmit = () => {
    dispatch(setLoader(true));
    setTimeout(() => {
      dispatch(setLoader(false));
      toast.info('Thank you for your feedback. We will definitely publish it after moderation');
      onCloseModal();
    }, randomInteger(800, 3000));
  };

  return (
    <form className={s.form} onSubmit={(e) => handleFormSubmit(e, handleSubmit)} noValidate>
      <h2 className={s.title}>Leave a review</h2>
      <div className={s.inputs_wrapper}>
        <Input
          name="firstName"
          label="First Name"
          placeholder="Enter your first name"
          value={form.firstName}
          onChange={setValue}
          error={errors?.firstName?.at(0)}
        />
        <Input
          name="lastName"
          label="Last Name"
          placeholder="Enter your last name"
          value={form.lastName}
          onChange={setValue}
          error={errors?.lastName?.at(0)}
        />
        <Input
          name="feedback"
          label="Review"
          placeholder="Enter your review"
          value={form.feedback}
          onChange={setValue}
          multiline
          error={errors?.feedback?.at(0)}
        />
        <Rating
          onChange={(value) => setValue(value, 'rating')}
          value={form.rating}
          size={40}
          allowHalfIcon
        />
      </div>
      <div className={s.actions}>
        <Button type="Submit" label="Send" round="block" />
      </div>
    </form>
  );
};

export default FeedbackForm;
