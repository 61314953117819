import { useState } from 'react';

import useValidateState from './useValidateState';

const useForm = (formState, validationScheme) => {
  const [form, setForm] = useState(() => formState || {});
  const { errors, handleFormSubmit, resetValidation } = useValidateState(validationScheme, form);

  const setValue = (value, name) => {
    setForm((curState) => ({ ...curState, [name]: value }));
  };

  const reset = () => {
    setForm(() => formState || {});
  };

  return { setValue, form, handleFormSubmit, errors, reset, resetValidation };
};

export default useForm;
