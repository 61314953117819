import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { NavigationScroll } from 'pages/_Layout';
import { ROUTER_PATHS } from 'constants/enums';
import { PUBLIC_ROUTES } from './routes';

const Routers = () => {
  return (
    <Router>
      <NavigationScroll>
        <Routes>
          {PUBLIC_ROUTES.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="*" element={<Navigate replace to={ROUTER_PATHS.HOME} />} />
        </Routes>
      </NavigationScroll>
    </Router>
  );
};

export default Routers;
