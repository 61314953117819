import { ModalLayout } from 'components/_Modals';
import FeedbackForm from './FeedbackForm';

const FeedbackModal = ({ ...modalProps }) => {
  return (
    <ModalLayout {...modalProps} size="sm">
      <FeedbackForm />
    </ModalLayout>
  );
};

export default FeedbackModal;
