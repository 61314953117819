import { Link } from 'react-router-dom';

import { Logo } from 'components';
import { CONTACTS, ROUTER_PATHS } from 'constants/enums';
// import { ReactComponent as FacebookIcon } from 'assets/images/facebook_oval.svg';
// import { ReactComponent as TwitterIcon } from 'assets/images/twitter_oval.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/instagram_oval.svg';

import s from './s.module.scss';

const Footer = () => {
  const date = new Date();

  return (
    <footer className={s.footer}>
      <div className="main_container">
        <div className={s.footer_wrapper}>
          <div className={s.info}>
            <Logo />
            <p className={s.info_text}>
              Our presets are a true must-have for content creators, influencers, bloggers, busy
              mamas, and creatives who wish to create stunning images and a cohesive aesthetic
              without spending hours manually editing each photo.
            </p>
          </div>
          <div className={s.nav}>
            <Link to={ROUTER_PATHS.SHOP} className={s.nav_item}>
              Shop
            </Link>
            <Link to={ROUTER_PATHS.BLOG} className={s.nav_item}>
              Blog
            </Link>
            <Link to={ROUTER_PATHS.REFUND_POLICY} className={s.nav_item}>
              Refund Policy
            </Link>
            <Link to={ROUTER_PATHS.TERMS_OF_SERVICE} className={s.nav_item}>
              Terms of Service
            </Link>
            <Link to={ROUTER_PATHS.PRIVACY_POLICY} className={s.nav_item}>
              Privacy Policy
            </Link>
          </div>
          <div className={s.contacts}>
            <div className={s.social}>
              {/* <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
            <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a> */}
              <a href={CONTACTS.INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
            </div>
            <div className={s.contact_item}>
              <p>Contact Us:</p>
              <a href={`mailto:${CONTACTS.EMAIL}`} className={s.nav_item}>
                {CONTACTS.EMAIL}
              </a>
            </div>
          </div>
        </div>
        <p className={s.footer_bottom}>
          Copyright© {date.getFullYear()} {CONTACTS.APP_NAME}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
