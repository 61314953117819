import axios from 'axios';

import { MESSAGES } from 'constants/enums';

const baseURL = process.env.REACT_APP_API;
// const baseURL = 'http://localhost:8000/api';

const DEF_INSTANCE = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

DEF_INSTANCE.interceptors.response.use(
  (response) => response?.data,
  (error) => Promise.reject(error?.response?.data || { message: MESSAGES.ERROR })
);

export { DEF_INSTANCE };
