/* eslint-disable camelcase */
import main1 from './main1.webp';
import main2 from './main2.webp';
import main3 from './main3.webp';
import main4 from './main4.webp';
import main5 from './main5.webp';
import main6 from './main6.webp';
import main7 from './main7.webp';
import main8 from './main8.webp';
import main9 from './main9.webp';
import after1_1 from './after1_1.jpg';
import after1_2 from './after1_2.jpg';
import after1_3 from './after1_3.jpg';
import after1_4 from './after1_4.jpg';
import after1_5 from './after1_5.jpg';
import after1_6 from './after1_6.jpg';
import after2_1 from './after2_1.png';
import after2_2 from './after2_2.jpg';
import after2_3 from './after2_3.jpg';
import after2_4 from './after2_4.jpg';
import after2_5 from './after2_5.png';
import after2_6 from './after2_6.jpg';
import after3_1 from './after3_1.jpg';
import after3_2 from './after3_2.jpg';
import after3_3 from './after3_3.jpg';
import after3_4 from './after3_4.jpg';
import after3_5 from './after3_5.jpg';
import after3_6 from './after3_6.jpg';
import after4_1 from './after4_1.jpg';
import after4_2 from './after4_2.jpg';
import after4_3 from './after4_3.jpg';
import after4_4 from './after4_4.jpg';
import after4_5 from './after4_5.jpg';
import after4_6 from './after4_6.jpg';
import after5_1 from './after5_1.jpg';
import after5_2 from './after5_2.jpg';
import after5_3 from './after5_3.jpg';
import after5_4 from './after5_4.jpg';
import after5_5 from './after5_5.jpg';
import after5_6 from './after5_6.jpg';
import after6_1 from './after6_1.jpg';
import after6_2 from './after6_2.jpg';
import after6_3 from './after6_3.jpg';
import after6_4 from './after6_4.jpg';
import after6_5 from './after6_5.jpg';
import after6_6 from './after6_6.jpg';
import after7_1 from './after7_1.jpg';
import after7_2 from './after7_2.jpg';
import after7_3 from './after7_3.jpg';
import after7_4 from './after7_4.jpg';
import after7_5 from './after7_5.jpg';
import after7_6 from './after7_6.jpg';
import after8_1 from './after8_1.jpg';
import after8_2 from './after8_2.jpg';
import after8_3 from './after8_3.jpg';
import after8_4 from './after8_4.jpg';
import after8_5 from './after8_5.jpg';
import after8_6 from './after8_6.jpg';
import after9_1 from './after9_1.jpg';
import after9_2 from './after9_2.jpg';
import after9_3 from './after9_3.jpg';
import after9_4 from './after9_4.jpg';
import after9_5 from './after9_5.jpg';
import after9_6 from './after9_6.jpg';
import before1_1 from './before1_1.jpg';
import before1_2 from './before1_2.jpg';
import before1_3 from './before1_3.jpg';
import before1_4 from './before1_4.jpg';
import before1_5 from './before1_5.jpg';
import before1_6 from './before1_6.jpg';
import before2_1 from './before2_1.png';
import before2_2 from './before2_2.jpg';
import before2_3 from './before2_3.jpg';
import before2_4 from './before2_4.jpg';
import before2_5 from './before2_5.png';
import before2_6 from './before2_6.webp';
import before3_1 from './before3_1.webp';
import before3_2 from './before3_2.webp';
import before3_3 from './before3_3.webp';
import before3_4 from './before3_4.webp';
import before3_5 from './before3_5.webp';
import before3_6 from './before3_6.webp';
import before4_1 from './before4_1.webp';
import before4_2 from './before4_2.webp';
import before4_3 from './before4_3.webp';
import before4_4 from './before4_4.webp';
import before4_5 from './before4_5.webp';
import before4_6 from './before4_6.webp';
import before5_1 from './before5_1.webp';
import before5_2 from './before5_2.webp';
import before5_3 from './before5_3.webp';
import before5_4 from './before5_4.webp';
import before5_5 from './before5_5.webp';
import before5_6 from './before5_6.webp';
import before6_1 from './before6_1.webp';
import before6_2 from './before6_2.webp';
import before6_3 from './before6_3.webp';
import before6_4 from './before6_4.webp';
import before6_5 from './before6_5.webp';
import before6_6 from './before6_6.webp';
import before7_1 from './before7_1.webp';
import before7_2 from './before7_2.webp';
import before7_3 from './before7_3.webp';
import before7_4 from './before7_4.webp';
import before7_5 from './before7_5.webp';
import before7_6 from './before7_6.webp';
import before8_1 from './before8_1.webp';
import before8_2 from './before8_2.webp';
import before8_3 from './before8_3.webp';
import before8_4 from './before8_4.webp';
import before8_5 from './before8_5.webp';
import before8_6 from './before8_6.webp';
import before9_1 from './before9_1.webp';
import before9_2 from './before9_2.webp';
import before9_3 from './before9_3.webp';
import before9_4 from './before9_4.webp';
import before9_5 from './before9_5.webp';
import before9_6 from './before9_6.webp';

const PRESET_IMAGES = {
  // 1
  main1,
  after1_1,
  after1_2,
  after1_3,
  after1_4,
  after1_5,
  after1_6,
  before1_1,
  before1_2,
  before1_3,
  before1_4,
  before1_5,
  before1_6,
  // 2
  main2,
  after2_1,
  after2_2,
  after2_3,
  after2_4,
  after2_5,
  after2_6,
  before2_1,
  before2_2,
  before2_3,
  before2_4,
  before2_5,
  before2_6,
  // 3
  main3,
  after3_1,
  after3_2,
  after3_3,
  after3_4,
  after3_5,
  after3_6,
  before3_1,
  before3_2,
  before3_3,
  before3_4,
  before3_5,
  before3_6,
  // 4
  main4,
  after4_1,
  after4_2,
  after4_3,
  after4_4,
  after4_5,
  after4_6,
  before4_1,
  before4_2,
  before4_3,
  before4_4,
  before4_5,
  before4_6,
  // 5
  main5,
  after5_1,
  after5_2,
  after5_3,
  after5_4,
  after5_5,
  after5_6,
  before5_1,
  before5_2,
  before5_3,
  before5_4,
  before5_5,
  before5_6,
  // 6
  main6,
  after6_1,
  after6_2,
  after6_3,
  after6_4,
  after6_5,
  after6_6,
  before6_1,
  before6_2,
  before6_3,
  before6_4,
  before6_5,
  before6_6,
  // 7
  main7,
  after7_1,
  after7_2,
  after7_3,
  after7_4,
  after7_5,
  after7_6,
  before7_1,
  before7_2,
  before7_3,
  before7_4,
  before7_5,
  before7_6,
  // 8
  main8,
  after8_1,
  after8_2,
  after8_3,
  after8_4,
  after8_5,
  after8_6,
  before8_1,
  before8_2,
  before8_3,
  before8_4,
  before8_5,
  before8_6,
  // 9
  main9,
  after9_1,
  after9_2,
  after9_3,
  after9_4,
  after9_5,
  after9_6,
  before9_1,
  before9_2,
  before9_3,
  before9_4,
  before9_5,
  before9_6,
};

export default PRESET_IMAGES;
