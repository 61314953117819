export const saveStorage = (key, data) => {
  if (!window?.localStorage) {
    return;
  }
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const getStorage = (key) => {
  if (!window?.localStorage) {
    return null;
  }
  try {
    return JSON.parse(window.localStorage.getItem(key) || '');
  } catch {
    return null;
  }
};

export const removeStorage = (key) => {
  if (!window?.localStorage) {
    return null;
  }
  try {
    window.localStorage.removeItem(key);
    return null;
  } catch {
    return null;
  }
};

export const LS_KEYS = {
  CART: 'spc_cart',
};
