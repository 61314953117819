import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from 'assets/images/arrowThin.svg';

import s from './settings.module.scss';

const Arrow = ({ onClick, classNames, customStyle }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(s.arrow, classNames)}
      style={customStyle}
    >
      <ArrowIcon />
    </button>
  );
};

export const ReviewSetting = () => {
  return {
    slidesToShow: 4,
    nextArrow: <Arrow classNames={s.rotate} customStyle={{ top: '42%', right: '-20px' }} />,
    prevArrow: <Arrow customStyle={{ top: '42%', left: '-20px' }} />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
};

export const OtherPresetSetting = () => {
  return {
    slidesToShow: 3,
    nextArrow: <Arrow classNames={s.rotate} customStyle={{ top: '42%', right: '-20px' }} />,
    prevArrow: <Arrow customStyle={{ top: '42%', left: '-20px' }} />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
};
