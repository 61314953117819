import { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Pagination } from 'components';
import { SellCard } from 'components/_Cards';
import { setLoader } from 'store/loaderSlice';
import { randomInteger } from 'utils/randomize';
import PRESETS from 'constants/presets';
import { LIMITS, ROUTER_PATHS, ROUTER_PARAMS } from 'constants/enums';

import s from './s.module.scss';

const ShopBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const topBlockScrollRef = useRef(null);

  const [page, setPage] = useState(
    () => Number.parseInt(new URLSearchParams(search).get(ROUTER_PARAMS.PAGE), 10) || 0
  );

  const paginationPresets = PRESETS.filter((_, index) =>
    Array.from({ length: LIMITS.PRESETS_VIEW }, (__, i) => i + page * LIMITS.PRESETS_VIEW).includes(
      index
    )
  );
  const totalPages = Math.ceil(PRESETS.length / LIMITS.PRESETS_VIEW);

  const handleSetPage = (value) => {
    dispatch(setLoader(true));
    setTimeout(() => {
      dispatch(setLoader(false));
      topBlockScrollRef.current.scrollIntoView();
      setPage(value);
      navigate(`${ROUTER_PATHS.SHOP}?${ROUTER_PARAMS.PAGE}=${value}`);
    }, randomInteger(800, 2500));
  };

  return (
    <div ref={topBlockScrollRef} className="main_container scroll_bt30">
      <h2 className="title">SHOP ALL</h2>
      <div className={s.shop_wrapper}>
        {paginationPresets.map((preset) => (
          <SellCard key={preset.id} desc={preset} />
        ))}
      </div>
      <div className={s.pagination}>
        <Pagination totalPages={totalPages} onChange={handleSetPage} forcePage={page} />
      </div>
    </div>
  );
};

export default ShopBlock;
