import { useEffect, useRef } from 'react';

const useScriptLoad = (options) => {
  const { onLoadCallback, callbackOptions, locationAppend = document.body } = options || {};

  const scriptRef = useRef(null);

  useEffect(() => {
    if (onLoadCallback) {
      onLoadCallback(scriptRef, callbackOptions);
    }
    return () => {
      if (scriptRef.current) {
        locationAppend.removeChild(scriptRef.current);
      }
    };
  }, []);
};

export default useScriptLoad;
