import Intro from './Intro';
import About from './About';
import Sellers from './Sellers';
import Subscribe from './Subscribe';
import Faq from './Faq';
import ContactUs from './ContactUs';

const Home = () => {
  return (
    <div className="smooth_enter_an">
      <Intro />
      <About />
      <Sellers />
      <Subscribe />
      <Faq />
      <ContactUs />
    </div>
  );
};

export default Home;
