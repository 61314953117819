export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const trimFirstLetter = (string = '') => {
  if (!string) {
    return '';
  }
  return string.trim().charAt(0);
};

export const convertStatus = (string = '') => {
  if (!string) {
    return '';
  }
  return string.replaceAll('_', ' ').toLowerCase();
};
