import { OtherPresets, Feedback } from 'components';
import PresetBuy from './PresetBuy';

const Preset = () => {
  return (
    <div className="smooth_enter_an">
      <PresetBuy />
      <Feedback />
      <OtherPresets />
    </div>
  );
};

export default Preset;
