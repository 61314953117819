export const currencyFormat = (value, options) => {
  const { minDigits = 2, maxDigits, currency = 'EUR', format = 'en-US' } = options || {};

  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits ?? 0,
  });

  return formatter.format(value || 0);
};

export const percentFormat = (value, options) => {
  const { minDigits = 0, maxDigits, format = 'en-US' } = options || {};

  const valueNum = parseFloat(value || 0);
  const formatter = new Intl.NumberFormat(format, {
    style: 'percent',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits ?? 0,
  });

  return formatter.format(valueNum / 100);
};

export const numberFormat = (value, options) => {
  const { minDigits = 0, maxDigits, format = 'en-US' } = options || {};

  const formatter = new Intl.NumberFormat(format, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits ?? 0,
  });

  return formatter.format(value || 0);
};
