import { ReviewCard } from 'components/_Cards';
import { BasicSlider } from 'components/_Sliders';
import { ReviewSetting } from 'components/_Sliders/settings';
import { REVIEW_1 } from 'constants/review';

import s from './s.module.scss';

const Review = () => {
  return (
    <div className="main_container">
      <div className={s.review}>
        <BasicSlider settings={ReviewSetting()}>
          {REVIEW_1.map((review) => (
            <ReviewCard key={review.id} desc={review} />
          ))}
        </BasicSlider>
      </div>
    </div>
  );
};

export default Review;
