import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { percentFormat, currencyFormat } from 'utils/intlFormat';
import { priceWithDiscount } from 'utils/numberFormat';
import { isGoodInCart } from 'utils/checkData';
import { ROUTER_PATHS } from 'constants/enums';
import { ReactComponent as CartIcon } from 'assets/images/cart.svg';

import s from './s.module.scss';

const SellCard = ({ desc }) => {
  const { cart } = useSelector(({ cartSlice }) => ({
    cart: cartSlice.cart,
  }));

  return (
    <Link to={`${ROUTER_PATHS.PRESET}${desc.id}`} className={s.card}>
      <div className={s.img_wrapper}>
        <img src={desc?.mainImage} alt="seller" />
      </div>
      {desc?.discount ? (
        <p className={s.discount}>{`-${percentFormat(desc.discount)} off`}</p>
      ) : null}
      {desc?.name ? <p className={s.label}>{desc.name}</p> : null}
      {desc?.price ? (
        <p className={s.price_wrapper}>
          {desc?.discount ? (
            <span className={s.old_price}>{currencyFormat(desc.price)}</span>
          ) : null}
          <span className={s.price}>
            {currencyFormat(priceWithDiscount(desc.price, desc.discount))}
          </span>
        </p>
      ) : null}
      {isGoodInCart(desc?.id, cart) ? (
        <span className={s.cart_mark}>
          <CartIcon />
        </span>
      ) : null}
    </Link>
  );
};

export default SellCard;
