import { Parallax } from 'react-scroll-parallax';
import clsx from 'clsx';

import s from './s.module.scss';

const AboutCard = ({ rtl = 'left', image, description }) => {
  const rtlCN = {
    left: s.left,
    right: s.right,
  }[rtl];

  return (
    <div className={clsx(s.card, rtlCN)}>
      <Parallax speed={rtl === 'left' ? -4 : 8} className={s.image_wrapper}>
        <img src={image} alt="about" />
      </Parallax>
      <div className={s.desc_wrapper}>
        {description?.map((text) => (
          <p key={text}>{text}</p>
        ))}
      </div>
    </div>
  );
};

export default AboutCard;
