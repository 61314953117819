import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Pagination } from 'components';
import { ArticleCard } from 'components/_Cards';
import { setLoader } from 'store/loaderSlice';
import { randomInteger } from 'utils/randomize';
import { ROUTER_PATHS, ROUTER_PARAMS, LIMITS } from 'constants/enums';
import BLOG from 'constants/blog';

import s from './s.module.scss';

const Articles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const topBlockScrollRef = useRef(null);

  const [page, setPage] = useState(
    () => Number.parseInt(new URLSearchParams(search).get(ROUTER_PARAMS.PAGE), 10) || 0
  );

  const paginationBlog = BLOG.filter((_, index) =>
    Array.from({ length: LIMITS.BLOG_VIEW }, (__, i) => i + page * LIMITS.PRESETS_VIEW).includes(
      index
    )
  );
  const totalPages = Math.ceil(BLOG.length / LIMITS.PRESETS_VIEW);

  const handleSetPage = (value) => {
    dispatch(setLoader(true));
    setTimeout(() => {
      dispatch(setLoader(false));
      topBlockScrollRef.current.scrollIntoView();
      setPage(value);
      navigate(`${ROUTER_PATHS.BLOG}?${ROUTER_PARAMS.PAGE}=${value}`);
    }, randomInteger(800, 2500));
  };

  return (
    <div className="main_container mb60">
      <div ref={topBlockScrollRef} className={clsx(s.blog, 'mb60', 'scroll_bt30')}>
        {paginationBlog.map((article) => (
          <ArticleCard key={article.id} content={article} />
        ))}
      </div>
      <div className={s.pagination}>
        <Pagination totalPages={totalPages} onChange={handleSetPage} forcePage={page} />
      </div>
    </div>
  );
};

export default Articles;
