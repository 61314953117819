import clsx from 'clsx';

import s from './s.module.scss';

const Button = ({
  onClick,
  label,
  isDisabled,
  icon,
  theme = 'blueBlack',
  type = 'button',
  size = 'md',
  round = 'oval',
}) => {
  const themeCN = {
    blueBlack: s.blueBlack,
    white: s.white,
    whiteOutline: s.whiteOutline,
    whiteBlue: s.whiteBlue,
    lightBlue: s.lightBlue,
    lightBlueSimple: s.lightBlueSimple,
    grayTransparent: s.grayTransparent,
  }[theme];

  const sizeCN = {
    md: s.md,
    lg: s.lg,
  }[size];

  const roundCN = {
    oval: s.oval,
    block: s.block,
  }[round];

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(s.button, sizeCN, roundCN, themeCN, isDisabled && s.disabled)}
      disabled={isDisabled}
    >
      {icon || null}
      {label}
    </button>
  );
};

export default Button;
