import { combineReducers, configureStore } from '@reduxjs/toolkit';

import loaderSlice from './loaderSlice';
import cartSlice from './cartSlice';

const rootReducer = combineReducers({ loaderSlice, cartSlice });

export const store = configureStore({
  reducer: rootReducer,
});
