import { useScriptLoad } from 'hooks';
import { Button } from 'components';
import { currencyFormat } from 'utils/intlFormat';
import { onLoadFastSpring } from 'utils/scriptLoad';

import s from './s.module.scss';

const FastSprings = ({ totalAmount, cart }) => {
  useScriptLoad({
    onLoadCallback: onLoadFastSpring,
  });

  const handleCheckout = () => {
    const products = cart.map(({ id, count }) => ({
      path: id,
      quantity: count,
    }));

    fastspring.builder.push({
      reset: true,
      products,
      checkout: true,
    });
  };

  return (
    <div className={s.payment_wrapper}>
      <p className={s.total_price}>
        Total Price: <span className="col_blue_wood">{currencyFormat(totalAmount)}</span>
      </p>
      <div className={s.actions}>
        <Button label="Buy now!" size="lg" round="block" onClick={handleCheckout} />
      </div>
    </div>
  );
};

export default FastSprings;
