import * as yup from 'yup';

const MESSAGES = {
  REQUIRED: 'Field is required',
  EMAIl_VALID: 'Enter a valid email',
  PHONE_VALID: 'Enter a valid phone number',
  FILE: 'Please upload the files',
  PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  AGE_LIMIT: 'You must be 16 years old',
  DATE_VALID: 'Enter a valid date',
  ZIP_VALID: 'Enter a valid ZIP code',
  AMOUNT_MIN: 'Enter an amount greater than 0',
  NUMBER_TYPE: 'Enter a number',
  CITY_CORRECT: 'Enter the correct city',
  PERCENTAGE_VALID: 'Enter a valid percentage',
  VALUE_VALID: 'Enter a valid value',
};

export const SUBSCRIBE_SCHEME = yup.object().shape({
  email: yup.string().email(MESSAGES.EMAIl_VALID).required(MESSAGES.REQUIRED),
});

export const FEEDBACK_SCHEME = yup.object().shape({
  firstName: yup.string().required(MESSAGES.REQUIRED),
  lastName: yup.string().required(MESSAGES.REQUIRED),
  feedback: yup.string().required(MESSAGES.REQUIRED),
  rating: yup.number().required(MESSAGES.REQUIRED),
});

export const CONTACT_US_SCHEME = yup.object().shape({
  email: yup.string().email(MESSAGES.EMAIl_VALID).required(MESSAGES.REQUIRED),
  name: yup.string().required(MESSAGES.REQUIRED),
  question: yup.string().required(MESSAGES.REQUIRED),
});

export const PAYMENT_SCHEME = yup.object().shape({
  email: yup.string().email(MESSAGES.EMAIl_VALID).required(MESSAGES.REQUIRED),
  lastName: yup.string().required(MESSAGES.REQUIRED),
  firstName: yup.string().required(MESSAGES.REQUIRED),
});

export const EMPTY_SCHEME = yup.object().shape({});
export const EMPTY_ARRAY_SCHEME = yup.array();
