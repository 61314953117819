import feedback1 from './feedback1.webp';
import feedback2 from './feedback2.webp';
import feedback3 from './feedback3.webp';
import feedback4 from './feedback4.webp';
import feedback5 from './feedback5.webp';
import feedback6 from './feedback6.webp';

const IMAGES = {
  feedback1,
  feedback2,
  feedback3,
  feedback4,
  feedback5,
  feedback6,
};

export default IMAGES;
