import { useBoolean } from 'hooks';
import { Button } from 'components';
import FeedbackModal from './FeedbackModal';

import s from './s.module.scss';

const Feedback = () => {
  const [isOpenReviewModal, setIsOpenReviewModal] = useBoolean(false);

  return (
    <>
      <div className="main_container mb60">
        <h3 className="title">DID YOU LIKE THIS PRESET?</h3>
        <p className={s.text}>You can share your impressions by leaving us a review</p>
        <div className={s.actions}>
          <Button
            label="Leave a review"
            round="block"
            size="lg"
            onClick={setIsOpenReviewModal.on}
          />
        </div>
      </div>
      <FeedbackModal isOpen={isOpenReviewModal} onClose={() => setIsOpenReviewModal.off()} />
    </>
  );
};

export default Feedback;
