import clsx from 'clsx';

import s from './s.module.scss';

const Input = ({
  label,
  type = 'text',
  value,
  onChange,
  placeholder,
  isDisabled = false,
  icon,
  theme = 'blueWood',
  error,
  multiline = false,
  heightTextArea = 100,
  name,
}) => {
  const themeCN = {
    whiteTrans: s.whiteTrans,
    blueWood: s.blueWood,
  }[theme];

  const handleChange = ({ target }) => {
    if (onChange) {
      onChange(target.value, name);
    }
  };

  if (multiline) {
    return (
      <div className={s.input_wrapper}>
        {label ? <span className={s.label}>{label}</span> : null}
        <textarea
          style={{ height: `${heightTextArea}px` }}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          className={clsx(s.input, themeCN)}
        />
        {error ? <span className={s.error}>{error}</span> : null}
      </div>
    );
  }

  return (
    <div className={s.input_wrapper}>
      {label ? <span className={s.label}>{label}</span> : null}
      <div className={s.input_block}>
        {icon || null}
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          className={clsx(s.input, themeCN)}
          onWheel={({ target }) => target.blur()}
          disabled={isDisabled}
        />
      </div>
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default Input;
