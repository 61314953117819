import { IntroPage } from 'components';
import IMAGE from 'assets/images';
import Payment from './Payment';

const Cart = () => {
  return (
    <div className="smooth_enter_an mb60">
      <IntroPage title="Payment" image={IMAGE.cart} />
      <Payment />
    </div>
  );
};

export default Cart;
