import { createSlice } from '@reduxjs/toolkit';

import { getStorage, saveStorage, LS_KEYS } from 'utils/localStorage';
import PRESETS from 'constants/presets';

const initialState = {
  cart:
    getStorage(LS_KEYS.CART)?.reduce((acc, item) => {
      const foundPreset = PRESETS.find((preset) => preset.id === item.id);
      return [...acc, { ...item, ...({ ...foundPreset, description: '' } || {}) }];
    }, []) || [],
};

const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      const cartFromStorage = getStorage(LS_KEYS.CART);
      const addedGood = PRESETS.find((preset) => preset.id === payload.id);

      if (!addedGood) {
        return;
      }

      saveStorage(LS_KEYS.CART, [...(cartFromStorage || []), payload]);
      state.cart = [...state.cart, { ...payload, ...addedGood, description: '' }];
    },
    removeFromCart: (state, { payload }) => {
      const cartFromStorage = getStorage(LS_KEYS.CART);
      saveStorage(LS_KEYS.CART, [
        ...(cartFromStorage?.filter((good) => good.id !== payload) || []),
      ]);
      state.cart = state.cart.filter((good) => good.id !== payload);
    },
    changeGoodCount: (state, { payload }) => {
      state.cart = state.cart.map((good) => {
        if (good.id === payload.id) {
          return { ...good, count: good.count + payload.count };
        }
        return good;
      });
    },
    clearCart: (state) => {
      state.cart = [];
      saveStorage(LS_KEYS.CART, []);
    },
  },
});

export const { addToCart, removeFromCart, changeGoodCount, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
