import { Link } from 'react-router-dom';
import { ParallaxBanner } from 'react-scroll-parallax';

import { Button } from 'components';
import { ROUTER_PATHS } from 'constants/enums';
import IMAGE from 'assets/images';

import s from './s.module.scss';

const Intro = () => {
  return (
    <div className={s.intro}>
      <ParallaxBanner layers={[{ image: IMAGE.bgHill, speed: -20 }]} className={s.parallax}>
        <div className={s.parallax_content}>
          <Link to={ROUTER_PATHS.SHOP}>
            <Button label="Explore" size="lg" round="block" theme="grayTransparent" />
          </Link>
          <h2 className="intro_title">
            Find your signature aesthetic and create the dreamiest photos with our easy to use, one
            click photo filters!
          </h2>
        </div>
      </ParallaxBanner>
      <p className={s.hint}>SALE: 50% Off vintage film collection </p>
    </div>
  );
};

export default Intro;
