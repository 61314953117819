import { Link } from 'react-router-dom';

import { Button } from 'components';
import { SellCard } from 'components/_Cards';
import PRESETS from 'constants/presets';
import { ROUTER_PATHS } from 'constants/enums';

import s from './s.module.scss';

const Sellers = () => {
  return (
    <div className="main_container">
      <h2 className="title">Best sellers</h2>
      <div className={s.sellers_wrapper}>
        <SellCard desc={PRESETS[6]} />
        <SellCard desc={PRESETS[7]} />
        <SellCard desc={PRESETS[8]} />
      </div>
      <div className={s.btn_wrapper}>
        <Link to={ROUTER_PATHS.SHOP}>
          <Button label="Explore more" theme="white" size="lg" />
        </Link>
      </div>
    </div>
  );
};

export default Sellers;
