import { cloneElement } from 'react';
import { Modal } from 'react-responsive-modal';
import clsx from 'clsx';

import 'assets/style/helper.scss';
import s from './s.module.scss';

const ModalLayout = ({
  isOpen,
  onClose,
  size = 'md',
  theme = 'light',
  children,
  ...childrenProps
}) => {
  const handleClose = (_, callback) => {
    if (onClose) {
      onClose();
    }
    if (callback) {
      callback();
    }
  };

  const modalSize = {
    xs: s.xs,
    sm: s.sm,
    lg: s.lg,
    md: s.md,
    th: s.th,
  }[size];

  const modalTheme = {
    light: s.modal_light,
  }[theme];

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      center
      classNames={{
        modal: clsx(s.modal, modalSize, modalTheme),
        overlay: s.modal_overlay,
        closeButton: s.modal_closeBtn,
        modalAnimationIn: 'fade-in',
        modalAnimationOut: 'fade-out_modal',
      }}
      focusTrapped={false}
    >
      {cloneElement(children, { onCloseModal: handleClose, ...childrenProps })}
    </Modal>
  );
};

export default ModalLayout;
