import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeGoodCount } from 'store/cartSlice';
import { priceWithDiscount } from 'utils/numberFormat';
import { currencyFormat } from 'utils/intlFormat';
import { ROUTER_PATHS } from 'constants/enums';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import s from './s.module.scss';

const CartCard = ({ desc, onRemoveGoodFromCart }) => {
  const dispatch = useDispatch();

  const handleChangeGoodCount = (count) => {
    if ((desc.count < 2 && count < 0) || (desc.count > 98 && count > 0)) {
      return;
    }
    dispatch(changeGoodCount({ id: desc.id, count }));
  };

  return (
    <div className={s.cart}>
      <Link to={`${ROUTER_PATHS.PRESET}${desc.id}`} className={s.img_wrapper}>
        <img src={desc?.mainImage} alt="preset" />
      </Link>
      <div className={s.info_wrapper}>
        <p className={s.name}>{desc?.name}</p>
        <p className={s.price}>
          <span className={s.price_label}>Price:</span>
          {desc?.discount ? (
            <span className={s.price_old}>{currencyFormat(desc.price)}</span>
          ) : null}
          {currencyFormat(priceWithDiscount(desc.price, desc.discount))}
        </p>
        <div className={s.count}>
          <button type="button" className={s.count_btn} onClick={() => handleChangeGoodCount(-1)}>
            -
          </button>
          <p className={s.count_view}>{desc?.count}</p>
          <button type="button" className={s.count_btn} onClick={() => handleChangeGoodCount(1)}>
            +
          </button>
        </div>
      </div>
      <button type="button" className={s.close} onClick={() => onRemoveGoodFromCart(desc?.id)}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default CartCard;
