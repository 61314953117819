import { AboutCard } from 'components/_Cards';
import Images from 'assets/images';

import s from './s.module.scss';

const About = () => {
  return (
    <div className="main_container scroll_bt30">
      <h2 className="title">What are presets?</h2>
      <div className={s.about_wrapper}>
        <AboutCard
          image={Images.about1}
          description={[
            'Presets are high-quality photo filters that can be applied to your photos to give your photos a beautiful, signature look! Rather than trying to sort out all the different color and lighting settings on your own, our presets allow you to quickly apply our signature settings to your photos for beautiful photos in one click!',
            'Our Lightroom Presets are carefully crafted with love and dedication by a growing team of professional photographers and skilled retouchers passionate about visual storytelling. We hope to inspire and empower you to work smarter, not harder. Because less time spent editing, means more time doing the things you love and creating memories with the ones you love.',
          ]}
        />
        <AboutCard
          rtl="right"
          image={Images.about2}
          description={[
            'Adobe Lightroom is the most popular image editing software for photographers. This program can use the thousands of effects and presets available on the internet to expand its editing functions. In just a minute, we’ll take a look at the best of those presets.',
            'Lightroom presets are important, because they help you to edit your photos in record time by doing a lot of the work for you. Simply apply a pre-defined effect on your original image to see its results. Then, if you don’t like the result, revert back to your original image at any time',
            'Once you’ve downloaded and installed Lightroom on your device, you can start using our presets',
          ]}
        />
      </div>
    </div>
  );
};

export default About;
