import { Link } from 'react-router-dom';

import s from './s.module.scss';

const MenuItem = ({ label, menu }) => {
  return (
    <div className={s.nav_item}>
      {label}
      <nav className={s.nav_menu}>
        {menu?.map((item) => (
          <Link key={item.path} to={item.path} className={s.sub_item}>
            {item.label}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default MenuItem;
