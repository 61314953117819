export const ROUTER_PATHS = {
  HOME: '/',
  SHOP: '/shop',
  PRESET: '/preset/',
  CART: '/cart',
  BLOG: '/blog',
  ARTICLE: '/article/',
  REFUND_POLICY: '/refund-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  TWO_CHECKOUT_PAYMENT: '/4f6d3b23-033f-4cad-8440-8e0f691b981d/:token',
  FAST_SPRING_PAYMENT: '/4f6d3b23-033f-4cad-8440-7g7g691b541d/:token',
};

export const ROUTER_PARAMS = {
  PAGE: 'page',
};

export const CONTACTS = {
  APP_NAME: 'SimplyPresets',
  EMAIL: 'info@simply-presets.com',
  SITE_URL: 'https://simply-presets.com',
  INSTAGRAM_URL: 'https://www.instagram.com/simplypresetscom/',
};

export const LIMITS = {
  PRESETS_VIEW: 9,
  BLOG_VIEW: 9,
};

export const MESSAGES = {
  ERROR: 'Oops, something went wrong',
  PRESET_NOT_FOUND: 'Preset not found',
};

export const VALIDATION = {
  BLOCKED_COUNTRIES: ['Portugal', 'Spain'],
};
