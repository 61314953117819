import { useSelector } from 'react-redux';

import s from './s.module.scss';

const Loader = () => {
  const { isLoading } = useSelector(({ loaderSlice }) => ({
    isLoading: loaderSlice.isLoading,
  }));

  return isLoading ? (
    <div className={s.loader_overlay}>
      <div className={s.loader_wrapper}>
        <div className={s.clock_loader}>
          <div className={s.dot_center} />
        </div>
      </div>
    </div>
  ) : null;
};

export default Loader;
