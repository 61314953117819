import { Link } from 'react-router-dom';

import { ROUTER_PATHS } from 'constants/enums';
import { ReactComponent as LogoLight } from 'assets/images/logo_light.svg';
import { ReactComponent as LogoDark } from 'assets/images/logo_dark.svg';

import s from './s.module.scss';

const LogoIcon = {
  dark: <LogoDark />,
  light: <LogoLight />,
};

const Logo = ({ path = ROUTER_PATHS.HOME, onClick, theme = 'dark' }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link to={path} className={s.logo} onClick={handleOnClick}>
      {LogoIcon[theme]}
    </Link>
  );
};

export default Logo;
