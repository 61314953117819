/* eslint-disable camelcase */
import main1 from './main1.webp';
import main2 from './main2.webp';
import main3 from './main3.webp';
import main4 from './main4.webp';
import main5 from './main5.webp';
import main6 from './main6.webp';
import main7 from './main7.webp';
import main8 from './main8.webp';
import main9 from './main9.webp';
import main10 from './main10.webp';
import main11 from './main11.webp';
import main12 from './main12.webp';
import a1_1 from './a1_1.webp';
import a1_2 from './a1_2.webp';
import a1_3 from './a1_3.webp';
import a1_4 from './a1_4.webp';
import a1_5 from './a1_5.webp';
import a1_6 from './a1_6.jpg';
import a1_7 from './a1_7.jpg';
import a2_1 from './a2_1.webp';
import a2_2 from './a2_2.webp';
import a2_3 from './a2_3.webp';
import a2_4 from './a2_4.jpg';
import a2_5 from './a2_5.webp';
import a3_1 from './a3_1.webp';
import a3_2 from './a3_2.webp';
import a3_3 from './a3_3.webp';
import a3_4 from './a3_4.webp';
import a3_5 from './a3_5.webp';
import a4_1 from './a4_1.webp';
import a4_2 from './a4_2.webp';
import a4_3 from './a4_3.webp';
import a5_1 from './a5_1.webp';
import a5_2 from './a5_2.webp';
import a5_3 from './a5_3.webp';
import a6_1 from './a6_1.webp';
import a6_2 from './a6_2.jpg';
import a7_1 from './a7_1.webp';
import a7_2 from './a7_2.webp';
import a7_3 from './a7_3.webp';
import a8_1 from './a8_1.webp';
import a8_2 from './a8_2.webp';
import a8_3 from './a8_3.webp';
import a9_1 from './a9_1.avif';
import a9_2 from './a9_2.png';
import a9_3 from './a9_3.webp';
import a10_1 from './a10_1.webp';
import a10_2 from './a10_2.webp';
import a10_3 from './a10_3.webp';
import a11_1 from './a11_1.webp';
import a11_2 from './a11_2.jpg';
import a11_3 from './a11_3.webp';
import a11_4 from './a11_4.webp';
import a11_5 from './a11_5.jpg';
import a11_6 from './a11_6.webp';
import a12_1 from './a12_1.webp';
import a12_2 from './a12_2.jpg';

const Images = {
  main1,
  main2,
  main3,
  main4,
  main5,
  main6,
  main7,
  main8,
  main9,
  main10,
  main11,
  main12,
  a1_1,
  a1_2,
  a1_3,
  a1_4,
  a1_5,
  a1_6,
  a1_7,
  a2_1,
  a2_2,
  a2_3,
  a2_4,
  a2_5,
  a3_1,
  a3_2,
  a3_3,
  a3_4,
  a3_5,
  a4_1,
  a4_2,
  a4_3,
  a5_1,
  a5_2,
  a5_3,
  a6_1,
  a6_2,
  a7_1,
  a7_2,
  a7_3,
  a8_1,
  a8_2,
  a8_3,
  a9_1,
  a9_2,
  a9_3,
  a10_1,
  a10_2,
  a10_3,
  a11_1,
  a11_2,
  a11_3,
  a11_4,
  a11_5,
  a11_6,
  a12_1,
  a12_2,
};

export default Images;
