export const onLoadFastSpring = (ref, options) => {
  const { onSuccessCallback, onMount } = options || {};

  ref.current = document.createElement('script');
  ref.current.setAttribute(
    'src',
    'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.9.3/fastspring-builder.js'
  );
  ref.current.setAttribute('id', 'fsc-api');
  ref.current.setAttribute('async', true);
  ref.current.setAttribute('type', 'text/javascript');
  ref.current.setAttribute('data-storefront', process.env.REACT_APP_FAST_SPRING_STOREFRONT);
  if (onSuccessCallback) {
    window.fastSpringSuccessCallBack = onSuccessCallback;
    ref.current.setAttribute('data-popup-webhook-received', 'fastSpringSuccessCallBack');
  }
  ref.current.onload = () => {
    onMount?.();
  };

  document.head.appendChild(ref.current);
};
