import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToTop } from 'utils/pageUtils';

const NavigationScroll = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop({ behavior: 'auto' });
  }, [pathname]);

  return children;
};

export default NavigationScroll;
