import { ROUTER_PATHS } from 'constants/enums';
import BLOG from 'constants/blog';

export const INSTALL_MENU = [
  {
    label: BLOG[0].name,
    path: `${ROUTER_PATHS.ARTICLE}${BLOG[0].id}`,
  },
  {
    label: BLOG[1].name,
    path: `${ROUTER_PATHS.ARTICLE}${BLOG[1].id}`,
  },
  {
    label: BLOG[2].name,
    path: `${ROUTER_PATHS.ARTICLE}${BLOG[2].id}`,
  },
];
