import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useForm } from 'hooks';
import { Input, Button } from 'components';
import { setLoader } from 'store/loaderSlice';
import { randomInteger } from 'utils/randomize';
import { SUBSCRIBE_SCHEME } from 'constants/validateForm';
import { INITIAL_SUBSCRIBE_FORM } from './const';

import s from './s.module.scss';

const Subscribe = () => {
  const dispatch = useDispatch();

  const { form, setValue, errors, handleFormSubmit, resetValidation, reset } = useForm(
    INITIAL_SUBSCRIBE_FORM,
    SUBSCRIBE_SCHEME
  );

  const handleSubmit = () => {
    dispatch(setLoader(true));
    setTimeout(() => {
      dispatch(setLoader(false));
      resetValidation();
      reset();
      toast.info(
        'Thank you for subscribing. You will receive a 10% discount on your first purchase.'
      );
    }, randomInteger(1000, 3000));
  };

  return (
    <div className={s.subscribe}>
      <div className="main_container">
        <h3 className={s.title}>SUBSCRIBE TO OUR NEWSLETTER</h3>
        <p className={s.text}>
          Don`t miss out! Get 10% off your first order and be the first to know about new presets,
          the latest photography tips and trends, and exclusive offers delivered straight to your
          inbox.
        </p>
        <form
          className={s.subscribe_wrapper}
          onSubmit={(e) => handleFormSubmit(e, handleSubmit)}
          noValidate
        >
          <Input
            placeholder="Your e-mail"
            type="email"
            onChange={setValue}
            value={form.email}
            name="email"
            error={errors?.email?.at(0)}
            theme="whiteTrans"
          />
          <Button type="submit" label="SUBSCRIBE" round="block" theme="whiteBlue" />
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
