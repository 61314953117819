import { Rating as ReactRating } from 'react-simple-star-rating';

const Rating = ({
  value,
  onChange,
  readonly,
  size = 25,
  divider = 20,
  fillColor = '#4B749F',
  allowHalfIcon,
  transition = true,
}) => {
  const handleChange = (rate) => {
    if (onChange) {
      onChange(rate / divider);
    }
  };

  return (
    <ReactRating
      initialValue={value}
      onClick={handleChange}
      readonly={readonly}
      allowHalfIcon={allowHalfIcon}
      size={size}
      fillColor={fillColor}
      transition={transition}
      allowHover={false}
    />
  );
};

export default Rating;
