import { IntroPage } from 'components';
import IMAGE from 'assets/images';
import ShopBlock from './ShopBlock';
import Review from './Review';

const Shop = () => {
  return (
    <div className="smooth_enter_an">
      <IntroPage image={IMAGE.girlEyes} title="Get your preset free" />
      <ShopBlock />
      <Review />
    </div>
  );
};

export default Shop;
