export const isEmptyValue = (value) => {
  return value == null || value.length === 0;
};

export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const isVarNullable = (variable) => {
  return variable == null;
};
