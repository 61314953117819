import IMAGE from 'assets/images/presets';

const PRESETS = [
  {
    // TwoCheckout
    // id: '2D3MFMFNG9',
    // FastSprings
    id: 'b-w-soft',
    name: 'Simply-BW-S',
    price: 30,
    discount: 0,
    mainImage: IMAGE.main1,
    introImage: IMAGE.after1_2,
    images: [
      { before: IMAGE.before1_1, after: IMAGE.after1_1 },
      { before: IMAGE.before1_2, after: IMAGE.after1_2 },
      { before: IMAGE.before1_3, after: IMAGE.after1_3 },
      { before: IMAGE.before1_4, after: IMAGE.after1_4 },
      { before: IMAGE.before1_5, after: IMAGE.after1_5 },
      { before: IMAGE.before1_6, after: IMAGE.after1_6 },
    ],
    description: (
      <>
        <h2>Create Stunning Black and White Images with Our Elegant and Sensual Presets</h2>
        <p>
          Are you looking to create stunning black and white images with an elegant and sensual
          feel? Look no further! Our custom presets are here to enhance your monochrome photos with
          just one click.
        </p>
        <p>
          We`ve created five unique presets to give your black and white photos a sophisticated and
          alluring aesthetic. Our `Classic Elegance` preset adds a timeless touch of refinement,
          while our `Soft and Dreamy`` preset creates a romantic, dream-like atmosphere. Our
          `Sensual Glow` preset enhances skin tones and gives your images a warm, inviting feel, and
          our `Film Noir` preset adds drama and mystery with its high contrast, black-and-white
          look. Finally, our `Vintage Charm`` preset adds a touch of nostalgia with its warm, faded
          tones.
        </p>
        <p>
          Whether you`re a professional photographer looking to add a touch of elegance to your
          portfolio or an amateur looking to enhance your personal photos, our presets are
          compatible with both Lightroom Classic and Lightroom CC, making it easy for you to achieve
          the perfect black and white look for your images.
        </p>
        <p>
          So why spend hours editing when you can achieve stunning results with just one click? Try
          our presets today and create black and white images with a sophisticated and sensual feel
          that will leave a lasting impression!
        </p>
        <ul>
          <li>5 Desktop Presets </li>
          <li>5 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: 'JGBFH27CT9',
    // FastSprings
    id: 'green-mood',
    name: 'Simply-GM',
    price: 50,
    discount: 0,
    mainImage: IMAGE.main2,
    introImage: IMAGE.after2_3,
    images: [
      { before: IMAGE.before2_1, after: IMAGE.after2_1 },
      { before: IMAGE.before2_2, after: IMAGE.after2_2 },
      { before: IMAGE.before2_3, after: IMAGE.after2_3 },
      { before: IMAGE.before2_4, after: IMAGE.after2_4 },
      { before: IMAGE.before2_5, after: IMAGE.after2_5 },
      { before: IMAGE.before2_6, after: IMAGE.after2_6 },
    ],
    description: (
      <>
        <h2>Transform Your Photos with Our Moody Green, Tropical, Dark Orange, and Teal Presets</h2>
        <p>
          Are you tired of spending hours editing your photos, only to end up with lackluster
          results? Look no further! Our custom presets are here to transform your photos with just
          one click.
        </p>
        <p>
          We`ve created four unique presets to give your images a stunning, professional look. Our
          moody green preset adds depth and richness to your photos, while our tropical preset
          transports you to a sun-drenched paradise. Our dark orange preset adds warmth and
          intensity to your images, and our teal preset gives them a cool, edgy vibe.
        </p>
        <p>
          Whether you`re a professional photographer looking to streamline your workflow or an
          amateur looking to enhance your vacation photos, our presets are compatible with both
          Lightroom Classic and Lightroom CC, making it easy for you to achieve the perfect look for
          your images.
        </p>
        <p>
          So why spend hours editing when you can achieve stunning results with just one click? Try
          our presets today and transform your photos into works of art!
        </p>
        <ul>
          <li>4 Desktop Presets </li>
          <li>4 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: 'UYCYH8AC92',
    // FastSprings
    id: 'sunshine',
    name: 'Simply-SS',
    price: 30,
    discount: 0,
    mainImage: IMAGE.main3,
    introImage: IMAGE.after3_2,
    images: [
      { before: IMAGE.before3_1, after: IMAGE.after3_1 },
      { before: IMAGE.before3_2, after: IMAGE.after3_2 },
      { before: IMAGE.before3_3, after: IMAGE.after3_3 },
      { before: IMAGE.before3_4, after: IMAGE.after3_4 },
      { before: IMAGE.before3_5, after: IMAGE.after3_5 },
      { before: IMAGE.before3_6, after: IMAGE.after3_6 },
    ],
    description: (
      <>
        <h2>Add Warmth and Radiance to Your Photos with Our `Sunshine Presets`</h2>
        <p>
          Are you looking to add warmth and radiance to your photos? Look no further! Our `Sunshine`
          presets are here to enhance your images with just one click.
        </p>
        <p>
          We`ve created three unique presets to give your photos a warm and inviting feel. Our
          `Sunshine Bright` preset adds a burst of light to your photos, making them look more
          vibrant and cheerful. Our `Sun-kissed` preset adds a golden glow to your images, creating
          a warm and radiant atmosphere. Finally, our `Sunset Hues` preset adds a touch of warmth
          and depth to your photos, making them look like they were taken during the magical moments
          of sunset.
        </p>
        <p>
          Whether you`re a professional photographer looking to enhance your portfolio or an amateur
          looking to add warmth and radiance to your personal photos, our presets are compatible
          with both Lightroom Classic and Lightroom CC, making it easy for you to achieve the
          perfect look for your images.
        </p>
        <p>
          So why spend hours editing when you can achieve stunning results with just one click? Try
          our `Sunshine` presets today and add a warm and inviting feel to your photos that will
          brighten up your day!
        </p>
        <ul>
          <li>3 Desktop Presets </li>
          <li>3 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: 'TBA2MIY9SD',
    // FastSprings
    id: 'white-day',
    name: 'Simply-WD',
    price: 50,
    discount: 20,
    mainImage: IMAGE.main4,
    introImage: IMAGE.after4_4,
    images: [
      { before: IMAGE.before4_1, after: IMAGE.after4_1 },
      { before: IMAGE.before4_2, after: IMAGE.after4_2 },
      { before: IMAGE.before4_3, after: IMAGE.after4_3 },
      { before: IMAGE.before4_4, after: IMAGE.after4_4 },
      { before: IMAGE.before4_5, after: IMAGE.after4_5 },
      { before: IMAGE.before4_6, after: IMAGE.after4_6 },
    ],
    description: (
      <>
        <h2>Achieve a Peaceful and Pure Aesthetic with Our `White Day` Presets</h2>
        <p>
          Are you looking to create a peaceful and pure aesthetic in your photos? Look no further!
          Our `White Day` presets are here to enhance your images with just one click.
        </p>
        <p>
          We`ve created four unique presets to give your photos a peaceful, loving, pure, and
          virtuous look. Our `Peaceful Serenity` preset adds a calming effect to your images,
          creating a tranquil and serene atmosphere. Our `Loving Embrace` preset enhances the
          softness and warmth in your photos, adding a loving and gentle feel. Our `Pure Innocence`
          preset creates a clean and bright aesthetic, making your photos look pure and pristine.
          Finally, our `Virtuous Charm` preset adds a touch of elegance and refinement to your
          images, creating a sophisticated and virtuous atmosphere.
        </p>
        <p>
          Whether you`re a professional photographer looking to add a peaceful and pure aesthetic to
          your portfolio or an amateur looking to enhance your personal photos, our presets are
          compatible with both Lightroom Classic and Lightroom CC, making it easy for you to achieve
          the perfect look for your images.
        </p>
        <p>
          So why spend hours editing when you can achieve stunning results with just one click? Try
          our `White Day` presets today and create photos with a peaceful, loving, pure, and
          virtuous feel that will bring a sense of calm and serenity to your life.
        </p>
        <ul>
          <li>4 Desktop Presets </li>
          <li>4 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: '19LB0AZNZ8',
    // FastSprings
    id: 'pacific-blue',
    name: 'Simply-PB',
    price: 40,
    discount: 50,
    mainImage: IMAGE.main5,
    introImage: IMAGE.after5_1,
    images: [
      { before: IMAGE.before5_1, after: IMAGE.after5_1 },
      { before: IMAGE.before5_2, after: IMAGE.after5_2 },
      { before: IMAGE.before5_3, after: IMAGE.after5_3 },
      { before: IMAGE.before5_4, after: IMAGE.after5_4 },
      { before: IMAGE.before5_5, after: IMAGE.after5_5 },
      { before: IMAGE.before5_6, after: IMAGE.after5_6 },
    ],
    description: (
      <>
        <h2>Embrace the Calming Blues of the Ocean with Our `Pacific Blue` Presets</h2>
        <p>
          Are you looking to enhance your oceanic photos with beautiful blue tones? Look no further!
          Our `Pacific Blue` presets are here to transform your images into stunning works of art
          with just one click.
        </p>
        <p>
          We`ve created a set of custom presets that emphasize blue colors to give your photos a
          peaceful and calming atmosphere. Our `Ocean Breeze` preset adds a refreshing and cool feel
          to your images, mimicking the natural hues of the ocean. Our `Deep Blue` preset adds
          richness and depth to your blues, making your images look vibrant and bold. Our `Calm
          Waters` preset softens your blues, creating a tranquil and serene feel to your photos.
          Finally, our `Mystical Sea` preset adds a touch of mystery and intrigue to your blues,
          making your images look surreal and dreamlike.
        </p>
        <p>
          Whether you`re a professional photographer capturing the beauty of the ocean or an amateur
          looking to enhance your personal photos, our presets are compatible with both Lightroom
          Classic and Lightroom CC, making it easy for you to achieve the perfect look for your
          images.
        </p>
        <p>
          So why spend hours editing when you can achieve stunning results with just one click? Try
          our `Pacific Blue` presets today and create photos with beautiful blue tones that will
          transport you to the peaceful and calming atmosphere of the ocean.
        </p>
        <ul>
          <li>5 Desktop Presets </li>
          <li>5 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: '8EAZAJBWU9',
    // FastSprings
    id: 'wild-forest',
    name: 'Simply-WF',
    price: 100,
    discount: 0,
    mainImage: IMAGE.main6,
    introImage: IMAGE.after6_3,
    images: [
      { before: IMAGE.before6_1, after: IMAGE.after6_1 },
      { before: IMAGE.before6_2, after: IMAGE.after6_2 },
      { before: IMAGE.before6_3, after: IMAGE.after6_3 },
      { before: IMAGE.before6_4, after: IMAGE.after6_4 },
      { before: IMAGE.before6_5, after: IMAGE.after6_5 },
      { before: IMAGE.before6_6, after: IMAGE.after6_6 },
    ],
    description: (
      <>
        <h2>Bring the Untamed Beauty of Nature to Your Photos with Our `Wild Forrest` Presets</h2>
        <p>
          Do you love capturing the raw and untamed beauty of nature in your photos? Look no
          further! Our `Wild Forrest` presets are here to transform your images into stunning works
          of art with just one click.
        </p>
        <p>
          We`ve created a set of custom presets that highlight the natural beauty of forests to give
          your photos a wild and rugged feel. Our `Enchanted Forest` preset adds a touch of magic to
          your photos, enhancing the greens and giving your images a dreamy and mystical quality.
          Our `Mossy Green` preset enhances the green tones in your photos, creating a lush and
          vibrant forest atmosphere. Our `Misty Morning` preset adds a soft and ethereal quality to
          your images, creating a moody and atmospheric feel to your forest photos.
        </p>
        <p>
          Whether you`re a professional photographer capturing the beauty of nature or an amateur
          looking to enhance your personal photos, our presets are compatible with both Lightroom
          Classic and Lightroom CC, making it easy for you to achieve the perfect look for your
          images.
        </p>
        <p>
          So why spend hours editing when you can achieve stunning results with just one click? Try
          our `Wild Forrest` presets today and create photos with a wild and natural look that will
          transport you to the beauty and mystery of the forest.
        </p>
        <ul>
          <li>3 Desktop Presets </li>
          <li>3 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: 'QGW7NQY10C',
    // FastSprings
    id: 'dark-portrait',
    name: 'Simply-DP',
    price: 30,
    discount: 0,
    mainImage: IMAGE.main7,
    introImage: IMAGE.after7_2,
    images: [
      { before: IMAGE.before7_1, after: IMAGE.after7_1 },
      { before: IMAGE.before7_2, after: IMAGE.after7_2 },
      { before: IMAGE.before7_3, after: IMAGE.after7_3 },
      { before: IMAGE.before7_4, after: IMAGE.after7_4 },
      { before: IMAGE.before7_5, after: IMAGE.after7_5 },
      { before: IMAGE.before7_6, after: IMAGE.after7_6 },
    ],
    description: (
      <>
        <h2>Create the Perfect Moody Portrait with Our `Dark Portrait` Presets</h2>
        <p>
          Are you looking to create stunning and moody portrait photos with ease? Look no further
          than our `Dark Portrait` presets. With just one click, our custom presets will give your
          photos a perfect skin tone and a dark, moody look.
        </p>
        <p>
          We`ve created two presets that are specifically designed to enhance portrait photos. Our
          `Perfect Skin` preset smooths out skin tones and enhances the natural beauty of your
          subject`s skin. Our `Dark Mood` preset adds a moody and atmospheric quality to your
          portrait photos, creating a dramatic and intense effect.
        </p>
        <p>
          Our presets are compatible with both Lightroom Classic and Lightroom CC, so you can use
          them with ease, no matter what version of Lightroom you have. With just one click, you can
          transform your portrait photos into stunning works of art that are perfect for use on
          social media, as part of your photography portfolio, or as a gift for your loved ones.
        </p>
        <p>
          So why spend hours editing your photos when you can achieve stunning results with just one
          click? Try our `Dark Portrait` presets today and take your portrait photography to the
          next level with ease.
        </p>
        <ul>
          <li>2 Desktop Presets </li>
          <li>2 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
        <p>
          Our presets are perfect for beginners or even professionals looking for a quick and easy
          way to edit pictures on the go. Our presets are compatible with JPEG files (including
          Iphone and Android Phone pictures) as well as RAW images
        </p>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: 'Z1IJDUMMDE',
    // FastSprings
    id: 'architecture',
    name: 'Simply-A',
    price: 30,
    discount: 0,
    mainImage: IMAGE.main8,
    introImage: IMAGE.after8_4,
    images: [
      { before: IMAGE.before8_1, after: IMAGE.after8_1 },
      { before: IMAGE.before8_2, after: IMAGE.after8_2 },
      { before: IMAGE.before8_3, after: IMAGE.after8_3 },
      { before: IMAGE.before8_4, after: IMAGE.after8_4 },
      { before: IMAGE.before8_5, after: IMAGE.after8_5 },
      { before: IMAGE.before8_6, after: IMAGE.after8_6 },
    ],
    description: (
      <>
        <h2>Transform Your Architecture Photos with Our `Architecture` Presets</h2>
        <p>
          Are you looking to add a touch of atmosphere and drama to your architecture photos? Our
          `Architecture` presets are here to help. We`ve created three custom presets that will give
          your photos a low contrast, atmospheric, and dark look with just one click.
        </p>
        <p>
          Our `Cityscape` preset is perfect for urban architecture photography, enhancing the
          textures and details of buildings while creating a moody and atmospheric effect. Our
          `Minimalist` preset gives your photos a minimalist and sleek look, perfect for modern
          architecture photography. And our `Dramatic Shadows` preset adds drama and depth to your
          photos, enhancing the shadows and creating a moody and intense effect.
        </p>
        <p>
          With just one click, you can transform your architecture photos into stunning works of art
          that are perfect for use in your portfolio or as part of your marketing materials. Our
          presets are compatible with both Lightroom Classic and Lightroom CC, so you can use them
          with ease, no matter what version of Lightroom you have.
        </p>
        <p>
          So why spend hours editing your architecture photos when you can achieve stunning results
          with just one click? Try our `Architecture` presets today and take your architecture
          photography to the next level with ease.
        </p>
        <ul>
          <li>3 Desktop Presets </li>
          <li>3 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
        <p>
          Our presets are perfect for beginners or even professionals looking for a quick and easy
          way to edit pictures on the go. Our presets are compatible with JPEG files (including
          Iphone and Android Phone pictures) as well as RAW images
        </p>
      </>
    ),
  },
  {
    // TwoCheckout
    // id: '85C0ZTZAFN',
    // FastSprings
    id: 'minimal-black',
    name: 'Simply-MB',
    price: 30,
    discount: 0,
    mainImage: IMAGE.main9,
    introImage: IMAGE.after9_3,
    images: [
      { before: IMAGE.before9_1, after: IMAGE.after9_1 },
      { before: IMAGE.before9_2, after: IMAGE.after9_2 },
      { before: IMAGE.before9_3, after: IMAGE.after9_3 },
      { before: IMAGE.before9_4, after: IMAGE.after9_4 },
      { before: IMAGE.before9_5, after: IMAGE.after9_5 },
      { before: IMAGE.before9_6, after: IMAGE.after9_6 },
    ],
    description: (
      <>
        <h2>Create Stunning and Moody Images with Our `Minimal Black` Presets</h2>
        <p>
          Are you looking for a way to make your images stand out with a minimal yet moody look? Our
          `Minimal Black` presets are here to help. We`ve created three custom presets that will
          give your photos a catchy, dark and moody, teal and black look with just one click.
        </p>
        <p>
          Our `Teal and Black` preset is perfect for creating a unique and eye-catching look that
          will make your photos stand out. The preset enhances the blues and teals in your image
          while keeping the blacks deep and rich. Our `Minimalist` preset gives your photos a clean
          and modern look that is perfect for portfolio shots or social media posts. And our `Dark
          and Moody` preset creates a dark and dramatic effect that is ideal for adding atmosphere
          to your photos.
        </p>
        <p>
          With our `Minimal Black` presets, you can achieve stunning results in seconds. No need to
          spend hours editing your photos when you can simply apply our presets with just one click.
          Our presets are compatible with both Lightroom Classic and Lightroom CC, so you can use
          them with ease, no matter what version of Lightroom you have.
        </p>
        <p>
          So why wait? Try our `Minimal Black` presets today and give your images a unique and moody
          look that will make them stand out from the crowd.
        </p>
        <ul>
          <li>3 Desktop Presets </li>
          <li>3 Mobile Presets </li>
          <li>Free support</li>
          <li>No editing experience necessary</li>
          <li>Compatible with Lightroom Mobile & Desktop</li>
        </ul>
        <h3>Additional info </h3>
        <ul>
          <li>
            Lightroom Mobile is completely free! You don`t need to subscribe to Lightroom or even
            download the Lightroom desktop app. All you need is the free Lightroom app on your
            phone!
          </li>
          <li>Our preset will be sent to you immediately via email once payment is received.</li>
        </ul>
        <p>
          Our presets are perfect for beginners or even professionals looking for a quick and easy
          way to edit pictures on the go. Our presets are compatible with JPEG files (including
          Iphone and Android Phone pictures) as well as RAW images
        </p>
      </>
    ),
  },
];

export default PRESETS;
